import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
} from "react-native";
import Moment from "moment";
import LoadingTransparente from "../Components/LoadingTransparente";
import { COLOR_EMPRESARIAL, COLOR_SECUNDARIO} from "../utils/variables";
import {AnularPedidoxCodigo, CancelarAnular, VerMesa} from "../utils/consultasAPI/Mesas"
import {Icon} from "react-native-elements"
import { TouchableOpacity } from "react-native";
import {useParams } from 'react-router-dom'

export default function VerMesas() {
  const {codigo} = useParams();
  const [indexSelect,setIndexSelect]=useState(0);
  const [Mesa,setMesa]=useState(null);  
const Cargar= async ()=>
  {
  try {
    const data = await Promise.all([
      VerMesa(codigo),
      // Otras llamadas asíncronas si las tienes
    ]);
    setMesa(data[0]); 
  } catch (error) {
    console.log("errrrorrrrrr",error)
  }
 }

 useEffect(() => {
  const loadData = async() => {
    await Cargar();
  };
  loadData();
  },[]);
  

  function Anular()
  {
    AnularPedidoxCodigo(codigo).then(p=>{Cargar()}).catch(Cargar());
  };

  function Cancelar()
  {
    CancelarAnular(codigo).then(p=>{Cargar()}).catch(Cargar());
  };

    return(
    <View style={{backgroundColor:"#fff", flex:1,marginVertical:20}}>
      <div className="mx-auto container d-flex row justify-content-center">
        {Mesa?Mesa.IdPedido==0?<Text style={{fontFamily:"FontBold",textAlign:"center", fontSize:22, color:COLOR_EMPRESARIAL}}>No existe solictud pendiente de anulación</Text>:
      <div className="d-flex row col-lg-9 col-md-12 col-sm-12 col-xs-12">
      <Text style={{fontFamily:"FontBold",textAlign:"center", fontSize:22, color:COLOR_EMPRESARIAL}}>Solicitud de anulación de Venta</Text>
      <View style={{flex:1, marginVertical:5, borderColor:COLOR_EMPRESARIAL,padding:10, borderWidth:1, borderRadius:5}}>
      <Text style={{fontFamily:"FontBold",textAlign:"center", fontSize:16, color:COLOR_SECUNDARIO}}>{Mesa&&Mesa.Emisor}</Text>
      <Text style={{fontFamily:"FontBold",textAlign:"center", fontSize:16, color:COLOR_SECUNDARIO}}>{Mesa&&Mesa.Local}</Text>
      </View>
      <div style={{marginTop:20,marginBottom:20}} className="d-flex column justify-content-around">
        <TouchableOpacity onPress={()=>Anular()} >
          <Icon
            type='ionicon'
            name='shield-checkmark-sharp'
            color={"green"}
              size={100}
            />
        </TouchableOpacity>
        <TouchableOpacity onPress={()=>Cancelar()}>
          <Icon
            type='ionicon'
            name='close-circle-sharp'
            color={"red"}
              size={100}
            />
        </TouchableOpacity>
      </div>
      <div className="row">
        <div className="d-flex col-md-6 col-sm-12 col-xs-12"><Text style={{fontFamily:"FontRegular", fontSize:15, color:COLOR_EMPRESARIAL}}>Fecha Pedido: {Mesa&&Moment(Mesa.FechaPedido).format("DD/MM/yyyy hh:mm:ss a")}</Text></div>
        <div className="d-flex col-md-6 col-sm-12 col-xs-12"><Text style={{fontFamily:"FontRegular", fontSize:15, color:"red"}}>Fecha Anulacion: {Mesa&&Moment(Mesa.FechaAnulacion).format("DD/MM/yyyy hh:mm:ss a")}</Text></div>
      </div>
      <div className="row">
        <div className="d-flex col-md-6 col-sm-12 col-xs-12"><Text style={{fontFamily:"FontRegular", fontSize:15, color:COLOR_EMPRESARIAL}}>{Mesa&&Mesa.Comprobante}</Text></div>
        <div className="d-flex col-md-6 col-sm-12 col-xs-12"><Text style={{fontFamily:"FontRegular", fontSize:15, color:COLOR_EMPRESARIAL}}>Id Pedido: {Mesa&&Mesa.IdPedido}</Text></div>
      </div>
      <div className="row">
        <div className="d-flex col-md-6 col-sm-12 col-xs-12"><Text style={{fontFamily:"FontRegular", fontSize:15, color:COLOR_EMPRESARIAL}}>Personal: {Mesa&&Mesa.Mozo}</Text></div>
        <div className="d-flex col-md-6 col-sm-12 col-xs-12"><Text style={{fontFamily:"FontRegular", fontSize:15, color:"red"}}>Motivo: {Mesa&&Mesa.Observacion}</Text></div>
      </div>
      <Text style={{marginTop:20, fontFamily:"FontBold", fontSize:15, color:COLOR_SECUNDARIO}}>Detalle</Text>
      {Mesa&&<div style={{flex:1, marginBottom:10}}>
      <FlatList
//          initialScrollIndex={indexSelect}
          showsVerticalScrollIndicator={true}
          data={Mesa.DetalleComanda}
          style={{flex:1, marginVertical:5, borderColor:COLOR_EMPRESARIAL,padding:10, borderWidth:1, borderRadius:5}}
          renderItem={(cate) => ( 
          <LineaCuota
          item={cate.item}
          indexSelect={indexSelect}
          index={cate.item.IDProducto}
          setIndexSelect={setIndexSelect}
          />)
          }
          keyExtractor={(item, index) => index.toString()}
        />
        <div className="d-flex justify-content-end">
        <Text 
        style={{ textAlign:"right", fontFamily:"FontBold", fontSize:18, color:COLOR_EMPRESARIAL}}>TOTAL PEDIDO    S/ {Mesa.MontoTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
      </div></div>}
      <div>
      <Text style={{fontFamily:"FontBold", fontSize:15, color:COLOR_SECUNDARIO}}>Pagos</Text>
        {Mesa&&<div style={{flex:1, marginBottom:10}}>
      <FlatList
//          initialScrollIndex={indexSelect}
          showsVerticalScrollIndicator={true}
          data={Mesa.Pagos}
          style={{flex:1, marginVertical:5, borderColor:COLOR_EMPRESARIAL,padding:10, borderWidth:1, borderRadius:5}}
          renderItem={(cate) => ( 
          <LineaPago
          item={cate.item}
          indexSelect={indexSelect}
          index={cate.item.IDProducto}
          setIndexSelect={setIndexSelect}
          />)
          }
          keyExtractor={(item, index) => index.toString()}
        />
        </div>}
      </div></div>:<div><LoadingTransparente isVisible={true}/></div>}
        </div>
    </View>)
} 

const LineaCuota = ({ item, indexSelect, index }) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Text style={{ fontFamily: index === indexSelect ? "FontBold" : "FontLight", width: "15%" }}>{item.Cantidad.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
      <Text style={{ fontFamily: index === indexSelect ? "FontBold" : "FontLight", flex: 1 }}>{item.ProductoDetalleTotal}</Text>
      <Text style={{ fontFamily: index === indexSelect ? "FontBold" : "FontLight", width: "15%", textAlign: "right" }}>{item.Total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
    </View>
  );
};

const LineaPago = ({ item, indexSelect, index }) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Text style={{ fontFamily: index === indexSelect ? "FontBold" : "FontLight", width: "15%" }}></Text>
      <Text style={{ fontFamily: index === indexSelect ? "FontBold" : "FontLight", flex: 1 }}>{item.MetodoPago.Descripcion}</Text>
      <Text style={{ fontFamily: index === indexSelect ? "FontBold" : "FontLight", width: "15%", textAlign: "right" }}>{item.Monto.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
    </View>
  );
};

const TiempoTranscurrido = (props) => {
  const [tiempoTranscurrido, setTiempoTranscurrido] = useState('');
  const [recargando,setRecargando]=useState(true);
  useEffect(() => {
    const horaEspecifica = new Date(props.FechaPedido);
    const intervalo = setInterval(() => {
      const tiempoActual = new Date();
      const diferenciaTiempo = tiempoActual - horaEspecifica;

      const horas = Math.floor(diferenciaTiempo / (1000 * 60 * 60)).toString().padStart(2, '0');
      const minutos = Math.floor((diferenciaTiempo / (1000 * 60)) % 60).toString().padStart(2, '0');
      const segundos = Math.floor((diferenciaTiempo / 1000) % 60).toString().padStart(2, '0');

      setTiempoTranscurrido(`${horas}:${minutos}:${segundos}`);
    }, 500);
  
    setRecargando(false);
    return () => clearInterval(intervalo);
  }, [props.FechaPedido]);

  return (
    <View>
      <Text style={{fontFamily:"FontLight", fontSize:16}}>Tiempo mesa activa: {tiempoTranscurrido}</Text>
    </View>
  );
};  