import { registerRootComponent } from 'expo';
import React  from "react";
import VerMesa from "./app/screens/VerMesa";
import * as Font from "expo-font";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes, Link, HashRouter } from "react-router-dom";

export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      fontsLoaded: false
    };
  }
  componentDidMount() {

    Font.loadAsync({
      FontLight: require("./assets/fonts/OpenSans/OpenSans-Light.ttf"),
      FontLightItalic: require("./assets/fonts/OpenSans/OpenSans-LightItalic.ttf"),
      FontBold: require("./assets/fonts/OpenSans/OpenSans-Bold.ttf"),
      FontBoldItalic: require("./assets/fonts/OpenSans/OpenSans-BoldItalic.ttf"),
      FontMedium: require("./assets/fonts/OpenSans/OpenSans-Regular.ttf"),
      FontMediumItalic: require("./assets/fonts/OpenSans/OpenSans-Italic.ttf"),
      FontRegular: require("./assets/fonts/OpenSans/OpenSans-Regular.ttf"),
      FontBoton: require("./assets/fonts/MyriadPro/MyriadProBoldItalic.ttf"),
    }).then(()=>
    {
        this.setState({
  fontsLoaded: true,
  });  
    }
    );
  }

  render() {
    return (
      <HashRouter>
      <Routes>
  <Route index path="/:codigo" element={<VerMesa/>}/>
  <Route path="/" element={<VerMesa/>}/>
  </Routes></HashRouter>
    )
  }
}
registerRootComponent(App);
