class Usuario {
  Id = 0;
  Nombres = "";
  TipoDoc = "01";
  NumDoc = "";
  FechaNacimiento = "";
  Sexo = "";
  Correo = "";
  UserLogin = new UserLogin();
}
class UserLogin {
  user = "";
  password = "";
}
class Direccion {
  Id = "";
  Calle = "";
  Numero = "";
  Referencia = "";
  Distrito = "";
  Provincia = "";
  Departamento = "";
  Ubigeo = "";
}

class CategoriaDelivery {
  Id = 0;
  Nombre = "";
  Descripcion = "";
  Icono = "";
}

function EmpresaCesta(
  rucEmpresa,
  razonSocial,
  idSucursal,
  idAlmacen,
  MetodoEntrega
) {
  this.Ruc = rucEmpresa;
  this.RazonSocial = razonSocial;
  this.MetodoEntrega = MetodoEntrega;
  this.MetodoPago = 0;
  this.EsFactura = false;
  this.EsProgramado = false;
  this.FechaProgramado = "";
  this.RucFactura = "";
  this.RazonSocialFactura = "";
  this.SubTotal = 0;
  this.Delivery = -10;
  this.IdSucursal = idSucursal;
  this.IdAlmacen = idAlmacen;
  this.FechaReserva = "";
  this.NumPersonas = "";
  this.HoraReserva = "";
  this.MotivoReserva = "";
  this.Anticipo = 0;
  this.Descuento = -10;
}
function getCurrentDate(separator=''){

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  
  return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
  }
function GetMetodoPago(id, metodos) {
  if (metodos) {
    if (id == 3) return "Efectivo";
    else if (id == 1) return "Tarjeta de Crédito / Débito";
    else {
      var retorno = metodos.find((p) => p.Id == id);
      return retorno ? retorno.TipoPago : "Seleccionar...";
    }
  } else return "Seleccionar...";
  /*
  switch (id) {
    case 0:
      return "Seleccionar...";
    case 1:
      return "Tarjeta de Crédito / Débito - Online";
    case 2:
      return "Tarjeta de Crédito / Débito - POS";
    case 3:
      return "Efectivo";
    case 4:
      return "Transferencia - YAPE";
    case 5:
      return "Transferencia - LUQUITA";
    case 6:
      return "Transferencia - PLIN";
    case 7:
      return "Transferencia - TUNKI";
  }*/
}


function GetMetodoEntrega(id) {
  switch (id) {
    case 0:
      return "Seleccionar...";
    case 1:
      return "Delivery";
    case 2:
      return "Recojo en Tienda";
    case 3:
      return "Delivery programado";
    case 4:
      return "Reservar Mesa";
  }
}
function DiaSemana(id) {
  switch (id) {
    case 1:
      return "L";
    case 2:
      return "M";
    case 3:
      return "X";
    case 4:
      return "J";
    case 5:
      return "V";
    case 6:
      return "S";
    case 0:
      return "D";
  }
}
function GetCiudades(id) {
  switch (id) {
    case 0:
      return "Seleccionar ciudad...";
    case 1:
      return "Chimbote";
    case 2:
      return "Nvo. Chimbote";
  }
}
function Existe(lista, valor) {
  let existe = false;
  if (isArray(lista)) {
    lista.forEach((p) => {
      if (p == valor) {
        existe = true;
      }
    });
  }
  return existe;
}

function isArray(obj) {
  return !!obj && obj.constructor === Array;
}

function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const COLOR_EMPRESARIAL = "#22b5be";
const COLOR_SECUNDARIO = "#f8bb1b";

const COLOR_PLACEHOLDER = "#999";
export {
  Usuario,
  UserLogin,
  CategoriaDelivery,
  Direccion,
  COLOR_EMPRESARIAL,
  COLOR_SECUNDARIO,
  EmpresaCesta,
  GetMetodoPago,
  GetMetodoEntrega,
  GetCiudades,
  COLOR_PLACEHOLDER,
  EstadoEmpresa,
  DiaSemana,
  generateUUID,
  MesasDia,
  Existe,
  getCurrentDate
};

function MesasDia(horarios, dia) {
  if (Array.isArray(horarios)) {
    var HorarioDia = horarios.find((p) => p.DiaSemana % 7 == dia);
    console.log(
      "Horartooooooooooooooooooooooooooooooooooooooooooo",
      HorarioDia
    );
    if (HorarioDia) {
      return HorarioDia.Mesas;
    } else return 0;
  } else return 0;
}

function EstadoEmpresa(horarios) {
  console.log(
    "dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd",
    horarios
  );
  if (Array.isArray(horarios)) {
    var HoraActual = new Date();
    var HorarioDia = horarios.find(
      (p) => p.DiaSemana % 7 == HoraActual.getDay()
    );
    console.log(
      "Horartooooooooooooooooooooooooooooooooooooooooooo",
      HorarioDia
    );
    if (HorarioDia) {
      if (HorarioDia.Inicio <= HoraActual && HorarioDia.Fin >= HoraActual)
        return true;
      else if (HorarioDia.HorarioAdicional) {
        if (
          HorarioDia.AdicionalInicio <= HoraActual &&
          HorarioDia.AdicionalFin >= HoraActual
        )
          return true;
        else return false;
      } else return false;
    } else return false;
  } else return false;
}
