import {
  consultasAPI_conToken,
    consultasAPI_Get, consultasAPI_GetToken, consultasAPI_Post, consultasAPI_PostToken
  } from "./conexionAPI";
  import {
    DatosInicialesToken,
  } from "./InicioSesion";
  
  export function VerMesa(id) {
   console.log("este es el id",id)
  return consultasAPI_Get("Pedido/PreAnulacion?Codigo="+id).then((result) => {
    if (result.resultado){ 
      console.log("VISTA DE MESA",result.Datos) 
      return result.Datos;}
    else
    throw result.mensaje
    })
    .catch((error) => {
      throw "Error: " + error;
    });
}
export function CancelarAnular(codigo) {
 return consultasAPI_Get("Pedido/CancelarAnular?Codigo="+codigo).then((result) => {
   if (result.resultado){ 
     return result.Datos;}
   else
   throw result.mensaje
   })
   .catch((error) => {
     throw "Error: " + error;
   });
}
export function AnularPedidoxCodigo(id) {
 return consultasAPI_Get("Pedido/Anular?Codigo="+id).then((result) => {
  console.log(result);  
   if (result.resultado){ 
     return result.Datos;}
   else
   throw result.mensaje
   })
   .catch((error) => {
     throw "Error: " + error;
   });
}

export function VerMesaHistorial(id) {
  console.log("este es el id",id)
 return consultasAPI_GetToken("Mesas/VerMesaHistorial?id_mesa="+id).then((result) => {
   if (result.resultado)  
     return result.Datos;
   else
   throw result.mensaje
   })
   .catch((error) => {
     throw "Error: " + error;
   });
}

export function ListaProductos(id) {
   
  return consultasAPI_GetToken("Producto/ListaMesa?id="+id).then((result) => {
    if (result.resultado)  
      return result.Datos;
    else
    throw result.mensaje
    })
    .catch((error) => {
      throw "Error: " + error;
    });
}


export function CambiarMesa(origen, destino) {
   
  console.log("Mesas/Cambiar?origen="+origen+"&destino="+destino);
  return consultasAPI_GetToken("Mesas/Cambiar?origen="+origen+"&destino="+destino).then((result) => {
    if (result.resultado)  
      return result.Datos;
    else
    throw result.mensaje
    })
    .catch((error) => {
      throw "Error: " + error;
    });
}

export function CambiarNombre(origen, nombre) {
  return consultasAPI_GetToken("Mesas/CambiarNombreMesa?codigo="+origen+"&nombre="+nombre).then((result) => {
    if (result.resultado)  
      return result.Datos;
    else
    throw result.mensaje
    })
    .catch((error) => {
      throw "Error: " + error;
    });
}

export function AnularPedido(pedido) {
   
  console.log("Mesas/QuitarPedido?pedido="+JSON.stringify(pedido));
  return;
  
  return consultasAPI_PostToken("Mesas/QuitarPedido",JSON.stringify(pedido)).then((result) => {
    if (result.resultado)  
      return result.Datos;
    else
    throw result.mensaje
    })
    .catch((error) => {
      throw "Error: " + error;
    });
}

export function ListarMesas() {
   
    return consultasAPI_GetToken("Mesas/Ocupadas").then((result) => {
      if (result.resultado)  
        return result.Datos;
      else
      throw result.mensaje
      })
      .catch((error) => {
        throw "Error!!!: " + error;
      });
  }

  export function ListarAlmacenes() {
   
    return consultasAPI_PostToken("Producto/ListaAlmacenes").then((result) => {
      if (result.resultado)  
        return result.Datos;
      else
      throw result.mensaje
      })
      .catch((error) => {
        throw "Error!!!: " + error;
      });
  }


  export function RegistrarComanda(pedido,Mesa,Observaciones) {
   
    return DatosInicialesToken().then(p=>{
    
      let pedidon={Observaciones:Observaciones,ClienteSinRegistro:"MESA "+Mesa.Id, Personal:{IdPersonal:p.Id},Detalle:pedido,Mesa:{Id:Mesa.Id,Codigo:Mesa.Codigo,Nombre:Mesa.Nombre,Grupo:Mesa.Grupo}}
      console.log("Mesas/RegistrarComanda?_pedido="+JSON.stringify(pedidon));
      return consultasAPI_GetToken("Mesas/RegistrarComanda?_pedido="+JSON.stringify(pedidon)).then((result) => {
      if (result.resultado)  
        return result.Datos;
      else
      throw result.mensaje
      })
      .catch((error) => {
        throw "Error: " + error;
      });
    })
  }

  export function ImprimirPreCuenta(pedido) {
    return consultasAPI_PostToken("Mesas/PreCuenta?_idmesa="+pedido,pedido).then((result) => {
      if (result.resultado)  
        return true;
      else
      throw result.mensaje
      })
      .catch((error) => {
        throw "Error: " + error;
      });
  }